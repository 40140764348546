.black {
  color: #000;
}

.secondary {
  color: #222;
}

.secondary-2 {
  color: #333;
}
/*# sourceMappingURL=index.cb7520a0.css.map */
